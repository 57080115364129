<script setup lang="ts">
import type { CmsBlockText } from "@shopware/composables";
import { useCmsBlock } from "#imports";

const props = defineProps<{
  content: CmsBlockText;
  style: any;
  class: any;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const content = getSlotContent("content");
</script>
<template>
  <CmsGenericElement :content="content" />
</template>